import React, { useContext, useEffect } from "react";
// react plugin that prints a given react component

// reactstrap components11
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Progress,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";
import CurrencyFormat from "react-currency-format";

import DataContext from "context/DataContext";
import {
  getOneEntryCountSurvey,
  getTotalVerifiedSurvey,
} from "network/apiSurvey";
import { Donut, DonutValue } from "react-donut-component";
import NotificationAlert from "react-notification-alert";
import baseApi1 from "../../../api/BaseApi1";

const OutletSurveyReport = () => {
  const { region, area, territory, salesPoint, tms } = useContext(DataContext);
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const { jwt_token } = JSON.parse(localStorage.getItem("user"));
  //Filters
  const [filterStartDate, setFilterStartDate] = React.useState("");
  const [filterEndDate, setFilterEndDate] = React.useState("");
  const [filterRegion, setFilterRegion] = React.useState("");
  const [filterArea, setFilterArea] = React.useState("");
  const [filterTerritory, setFilterTerritory] = React.useState("");
  const [filterSalesPoint, setFilterSalesPoint] = React.useState("");
  const [selectedTms, setSelectedTms] = React.useState("");
  // Dashboard Data
  const [tmsPresent, setTmsPresent] = React.useState([]);
  const [easChart, setEasChart] = React.useState([]);
  const [portfolioChart, setPortfolioChart] = React.useState([]);
  const [focusChart, setFocusChart] = React.useState([]);
  const [brandSplit, setBrandSplit] = React.useState({});
  const [salesTrend, setSalesTrend] = React.useState({});
  const [dateWiseSales, setDateWiseSales] = React.useState({});
  // //Sub Charts
  const [subPortfolioChart, setSubPortfolioChart] = React.useState([]);
  const [subFocusChart, setSubFocusChart] = React.useState([]);
  const [subEasChart, setSubEasChart] = React.useState([]);
  const [subTmsChart, setSubTmsChart] = React.useState([]);
  const [survey, setSurvey] = React.useState({});
  const [totalVerifiedOTP, setTotalVerifiedOTP] = React.useState({});
  const [totalScan, setTotalScan] = React.useState({});

  const brandColorMap = {
    Navy: "#332972",
    "Sheikh SM": "#0b6bb5",
    "Sheikh FF": "#a7263a",
    "Sheikh LEP": "#c51e3c",
    Real: "#611b3d",
    K2: "#114172",
    "Navy Option": "#37023f",
    "Sheikh 100s": "#8b0101",
  };
  //Notification Alert
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "bc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Dashboard Notification
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  //Version Check
  useEffect(() => {
    const versionCheck = localStorage.getItem("version");
    if (versionCheck !== "v1") {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
  }, []);

  useEffect(() => {
    const getTotal = async () => {
      const totalVerifiedOTP = await getTotalVerifiedSurvey();
      setTotalVerifiedOTP(totalVerifiedOTP);
      const totalScan = await getOneEntryCountSurvey();
      setTotalScan(totalScan);
    };
    getTotal();
  }, []);
  const newfilteredArea = area.filter(
    (item) =>
      item.regionId ===
      (userInfo.region === undefined ? filterRegion : userInfo.region._id)
  );
  const newfilteredTerritory = territory.filter(
    (item) =>
      item.areaId ===
      (userInfo.area === undefined ? filterArea : userInfo.area._id)
  );
  const newfilteredSalesPoint = salesPoint?.filter(
    (item) =>
      item?.territory?._id ===
      (filterTerritory === "" ? userInfo?.territory?._id : filterTerritory)
  );
  const newfilteredTms = tms.filter(
    (x) =>
      x.territoryId ===
      (filterTerritory === "" ? userInfo?.territory?._id : filterTerritory)
  );
  const getTMSPresent = async () => {
    try {
      const response = await baseApi1.get(
        `/api/dashboard/charts2?startDate="2021-12-12"&endDate="2021-12-20"`
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setTmsPresent(response?.data?.tmsPresent?.data);
      }
    } catch (error) {
      notify("danger", error.response?.data?.message);
    }
  };
  const getChildTms = async () => {
    try {
      const response = await baseApi1.get(
        `/api/dashboard/child/tms?startDate=${filterStartDate}&endDate=${filterEndDate}`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setSubTmsChart(response?.data);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };
  const getEASChart = async () => {
    try {
      const response = await baseApi1.get(
        `/api/dashboard/eas?startDate=${filterStartDate}&endDate=${filterEndDate}&region=${
          userInfo.region === undefined ? filterRegion : userInfo.region._id
        }&area=${
          userInfo.area === undefined ? filterArea : userInfo.area._id
        }&territory=${
          userInfo.territory === undefined
            ? filterTerritory
            : userInfo.territory._id
        }&salesPoint=${filterSalesPoint}&tms=${selectedTms}`
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setEasChart(response.data.data);
      }
    } catch (error) {
      notify("danger", error.response.message);
    }
  };
  const getPortfolioChart = async () => {
    try {
      const response = await baseApi1.get(
        `/api/dashboard/portfolio?startDate=${filterStartDate}&endDate=${filterEndDate}&region=${
          userInfo.region === undefined ? filterRegion : userInfo.region._id
        }&area=${
          userInfo.area === undefined ? filterArea : userInfo.area._id
        }&territory=${
          userInfo.territory === undefined
            ? filterTerritory
            : userInfo.territory._id
        }&salesPoint=${filterSalesPoint}&tms=${selectedTms}`
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setPortfolioChart(response.data.data);
      }
    } catch (error) {
      notify("danger", error.response?.message);
    }
  };

  const getSurveyCount = async () => {
    try {
      const response = await baseApi1.get(
        `/api/eas/web-entry/count?startDate=${filterStartDate}&endDate=${filterEndDate}&region=${
          userInfo.region === undefined ? filterRegion : userInfo.region._id
        }&area=${
          userInfo.area === undefined ? filterArea : userInfo.area._id
        }&territory=${
          userInfo.territory === undefined
            ? filterTerritory
            : userInfo.territory._id
        }&salesPoint=${filterSalesPoint}&tms=${selectedTms}`
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setSurvey(response.data);
      }
    } catch (error) {
      notify("danger", error.response?.message);
    }
  };
  const getFocusChart = async () => {
    try {
      const response = await baseApi1.get(
        `/api/dashboard/focused-volume?startDate=${filterStartDate}&endDate=${filterEndDate}&region=${
          userInfo.region === undefined ? filterRegion : userInfo.region._id
        }&area=${
          userInfo.area === undefined ? filterArea : userInfo.area._id
        }&territory=${
          userInfo.territory === undefined
            ? filterTerritory
            : userInfo.territory._id
        }&salesPoint=${filterSalesPoint}${selectedTms}`
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setFocusChart(response.data.data);
      }
    } catch (error) {
      notify("danger", error?.response?.data?.message);
    }
  };
  const getChildPortfolioChart = async () => {
    try {
      const response = await baseApi1.get(
        `/api/dashboard/child/portfolio?startDate=${filterStartDate}&endDate=${filterEndDate}`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setSubPortfolioChart(response.data);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };
  const getChildFocusChart = async () => {
    try {
      const response = await baseApi1.get(
        `/api/dashboard/child/focused-volume?startDate=${filterStartDate}&endDate=${filterEndDate}`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setSubFocusChart(response.data);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };
  const getChildEasChart = async () => {
    try {
      const response = await baseApi1.get(
        `/api/dashboard/child/eas?startDate=${filterStartDate}&endDate=${filterEndDate}`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setSubEasChart(response.data);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };
  const getBrandSplit = async () => {
    try {
      const response = await baseApi1.get(
        `/api/dashboard/bar?startDate=${filterStartDate}&endDate=${filterEndDate}&region=${
          userInfo.region === undefined ? filterRegion : userInfo.region._id
        }&area=${
          userInfo.area === undefined ? filterArea : userInfo.area._id
        }&territory=${
          userInfo.territory === undefined
            ? filterTerritory
            : userInfo.territory._id
        }&salesPoint=${filterSalesPoint}&tms=${selectedTms}`
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setBrandSplit(response.data);
      }
    } catch (error) {
      notify("danger", error?.response?.data?.message);
    }
  };
  const getSalesTrend = async () => {
    try {
      const response = await baseApi1.get(
        `/api/dashboard/line?startDate=${filterStartDate}&endDate=${filterEndDate}&region=${
          userInfo.region === undefined ? filterRegion : userInfo.region._id
        }&area=${
          userInfo.area === undefined ? filterArea : userInfo.area._id
        }&territory=${
          userInfo.territory === undefined
            ? filterTerritory
            : userInfo.territory._id
        }&salesPoint=${filterSalesPoint}&tms=${selectedTms}`
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setSalesTrend(response.data);
      }
    } catch (error) {
      console.log(error.response);
      notify("danger", error.response?.data?.message);
    }
  };
  const getDateWiseSales = async () => {
    try {
      const response = await baseApi1.get(
        `/api/dashboard/group_bar?startDate=${filterStartDate}&endDate=${filterEndDate}&region=${
          userInfo.region === undefined ? filterRegion : userInfo.region._id
        }&area=${
          userInfo.area === undefined ? filterArea : userInfo.area._id
        }&territory=${
          userInfo.territory === undefined
            ? filterTerritory
            : userInfo.territory._id
        }&salesPoint=${filterSalesPoint}&tms=${selectedTms}`
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setDateWiseSales(response.data);
      }
    } catch (error) {
      console.log(error.response);
      notify("danger", error?.response?.data?.message);
    }
  };
  const handleViewData = () => {
    getTMSPresent();
    getPortfolioChart();
    getEASChart();
    getFocusChart();
    getSurveyCount();
    if (
      userInfo.role === "Area Manager" ||
      userInfo.role === "Regional Manager" ||
      userInfo.role === "National Manager"
    ) {
      getChildPortfolioChart();
      getChildFocusChart();
      getChildEasChart();
    }

    if (userInfo.role === "Territory Manager") {
      getChildTms();
    }

    getBrandSplit();
    getSalesTrend();
    getDateWiseSales();
  };

  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader parentName="Survey Report" />
      <Container className="mt-6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>Survey Report Filters</h3>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <Row className="row-example">
                    <Col xs={4} sm={4}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example-date-input"
                          md="2"
                        >
                          Start Date
                        </label>
                        <Input
                          id="example-date-input"
                          defaultValue={filterStartDate}
                          type="date"
                          onChange={(e) =>
                            setFilterStartDate(
                              moment(new Date(e.target.value)).format(
                                "yyyy-MM-DD"
                              )
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={4} sm={4}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example-date-input"
                          md="2"
                        >
                          End Date
                        </label>

                        <Input
                          id="example-date-input"
                          defaultValue={filterEndDate}
                          type="date"
                          onChange={(e) =>
                            setFilterEndDate(
                              moment(new Date(e.target.value)).format(
                                "yyyy-MM-DD"
                              )
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={4} sm={4}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Region
                        </label>
                        {userInfo.region === undefined ? (
                          <Input
                            id="exampleFormControlSelect1"
                            onChange={(e) => setFilterRegion(e.target.value)}
                            type="select"
                          >
                            <option>Select</option>
                            {region.map((region) => (
                              <option value={region._id}>{region.name}</option>
                            ))}
                          </Input>
                        ) : (
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input
                              type="name"
                              value={userInfo.region.name}
                              disabled
                            />
                          </InputGroup>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="row-example">
                    <Col xs={3} sm={3}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Area
                        </label>
                        {userInfo.area === undefined ? (
                          <Input
                            id="exampleFormControlSelect1"
                            onChange={(e) => setFilterArea(e.target.value)}
                            type="select"
                          >
                            <option>Select</option>
                            {newfilteredArea.map((area) => (
                              <option value={area._id}>{area.name}</option>
                            ))}
                          </Input>
                        ) : (
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input
                              type="name"
                              value={userInfo.area.name}
                              disabled
                            />
                          </InputGroup>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={3} sm={3}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Territory
                        </label>
                        {userInfo.territory === undefined ? (
                          <Input
                            id="exampleFormControlSelect1"
                            onChange={(e) => setFilterTerritory(e.target.value)}
                            type="select"
                          >
                            <option>Select</option>
                            {newfilteredTerritory.map((territory) => (
                              <option value={territory._id}>
                                {territory.name}
                              </option>
                            ))}
                          </Input>
                        ) : (
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input
                              type="name"
                              value={userInfo.territory.name}
                              disabled
                            />
                          </InputGroup>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={3} sm={3}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Sales Point
                        </label>
                        <Input
                          id="exampleFormControlSelect1"
                          onChange={(e) => setFilterSalesPoint(e.target.value)}
                          type="select"
                        >
                          <option>Select</option>
                          {newfilteredSalesPoint.map((sp) => (
                            <option value={sp._id}>{sp.name}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs={3} sm={3}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          TMS
                        </label>
                        <Input
                          id="exampleFormControlSelect1"
                          onChange={(e) => setSelectedTms(e.target.value)}
                          type="select"
                        >
                          <option>Select</option>
                          {newfilteredTms?.map((tms) => (
                            <option value={tms._id}>
                              {`${tms.name} (${tms.phone})`}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="text-right">
                    <Button
                      onClick={handleViewData}
                      color="warning"
                      type="button"
                    >
                      View
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Container className="mt-1" fluid>
        <Card>
          <CardHeader className="d-flex justify-content-between">
            <h3 style={{ color: "#DB5A3B" }}>Outlet Survey Dashboard</h3>
          </CardHeader>
          <CardBody
            style={{ gap: "40px" }}
            className="d-flex gap-5 justify-content-around"
          >
            {/* Total Surveys Section */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h3 className="d-flex justify-content-center align-items-center">
                Total Surveys (Present Date Only)
              </h3>
              <ul className="list-unstyled my-4">
                <li>
                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      <div
                        style={{ backgroundColor: "orange" }}
                        className="icon icon-xs icon-shape text-white shadow rounded-circle"
                      ></div>
                    </div>
                    <div>
                      <span
                        style={{ fontWeight: "bold" }}
                        className="pl-2 text-md"
                      >
                        Completed Surveys (
                        {isNaN(tmsPresent[0]) ? 0 : tmsPresent[0]})
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      <div className="icon icon-xs icon-shape bg-yellow text-white shadow rounded-circle"></div>
                    </div>
                    <div>
                      <span
                        style={{ fontWeight: "bold" }}
                        className="pl-2 text-md"
                      >
                        Total Surveys (
                        {isNaN(tmsPresent[1]) ? 0 : tmsPresent[1]})
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
              <div
                style={{ marginTop: "auto" }}
                className="d-flex justify-content-center"
              >
                <Donut
                  style={{ margin: "auto" }}
                  color="black"
                  indicatorColor="black"
                  linecap="butt"
                  animate={true}
                  size={210}
                  strokeWidth={20}
                  styleIndicator={{
                    stroke: "Orange",
                    strokeLinecap: "round",
                  }}
                  styleTrack={{
                    stroke: "Yellow",
                    strokeWidth: 15,
                  }}
                  trackColor="#dfdff1"
                >
                  <DonutValue
                    style={{
                      fontWeight: "bold",
                    }}
                    styleSymbol={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    symbol="%"
                  >
                    {isNaN(Math.round((tmsPresent[0] / tmsPresent[1]) * 100))
                      ? 0
                      : Math.round((tmsPresent[0] / tmsPresent[1]) * 100)}
                  </DonutValue>
                </Donut>
              </div>
            </div>

            {/* Survey Details Section */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h3 className="d-flex justify-content-center align-items-center">
                Survey Details
              </h3>
              <ul className="list-unstyled my-4">
                <li>
                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      <div
                        style={{ backgroundColor: "chocolate" }}
                        className="icon icon-xs icon-shape text-white shadow rounded-circle"
                      ></div>
                    </div>
                    <div>
                      <span
                        style={{ fontWeight: "bold" }}
                        className="pl-2 text-md"
                      >
                        Surveys from QC Code ({survey?.count || 0})
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      <div
                        style={{ backgroundColor: "orange" }}
                        className="icon icon-xs icon-shape text-white shadow rounded-circle"
                      ></div>
                    </div>
                    <div>
                      <span
                        style={{ fontWeight: "bold" }}
                        className="pl-2 text-md"
                      >
                        Verified Surveys (
                        {isNaN(totalVerifiedOTP?.count)
                          ? 0
                          : totalVerifiedOTP?.count}
                        )
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      <div className="icon icon-xs icon-shape bg-yellow text-white shadow rounded-circle"></div>
                    </div>
                    <div>
                      <span
                        style={{ fontWeight: "bold" }}
                        className="pl-2 text-md"
                      >
                        Total QR Code Scans (
                        {isNaN(totalScan?.count) ? 0 : totalScan?.count})
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
              <div
                style={{ marginTop: "auto" }}
                className="d-flex justify-content-center"
              >
                <Donut
                  style={{ margin: "auto" }}
                  color="black"
                  indicatorColor="black"
                  linecap="butt"
                  animate={true}
                  size={210}
                  strokeWidth={20}
                  styleIndicator={{
                    stroke: "Chocolate",
                    strokeLinecap: "round",
                  }}
                  styleTrack={{
                    stroke: "Orange",
                    strokeWidth: 15,
                  }}
                  trackColor="#dfdff1"
                >
                  <DonutValue
                    style={{
                      fontWeight: "bold",
                    }}
                    styleSymbol={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    symbol="%"
                  >
                    {isNaN(
                      Math.round(
                        (survey?.count / totalVerifiedOTP?.count) * 100
                      )
                    )
                      ? 0
                      : Math.round(
                          (survey?.count / totalVerifiedOTP?.count) * 100
                        )}
                  </DonutValue>
                </Donut>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>

      {/* TMS Breakdowns Section */}
      <Container
        style={{
          display: userInfo.role === "Territory Manager" ? "block" : "none",
        }}
        className="mt-1"
        fluid
      >
        <Card>
          <CardHeader className="d-flex justify-content-between">
            <h3 style={{ color: "#DB5A3B" }}>Survey Performance Breakdown</h3>
          </CardHeader>
          <CardBody>
            {subTmsChart.map((x) => (
              <Card className="bg-gradient-primary border-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h4"
                        className="text-uppercase text-muted mb-0 text-white"
                      >
                        Survey Activity (Completed / Target)
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 text-white">
                        {`${x.achieved} / ${x.target}`}
                      </span>
                      <div className="progress-wrapper">
                        <div className="progress-info">
                          <div className="">
                            <span
                              style={{ fontSize: "15px" }}
                              className="text-white"
                            >
                              {`${x.name} (${x.enrollId})`}
                            </span>
                          </div>
                          <div className="progress-percentage">
                            <span className="text-white">
                              {Math.round((x.achieved / x.target) * 100) + "%"}
                            </span>
                          </div>
                        </div>
                        <Progress
                          color="success"
                          max={x.target}
                          value={x.achieved}
                        />
                      </div>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                        <i className="fas fa-chart-bar" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ))}
          </CardBody>
        </Card>
      </Container>
      <Container className="mt-1" fluid>
        <Card>
          <CardHeader className="d-flex justify-content-between">
            <h3 style={{ color: "#DB5A3B" }}>Outlet Survey Dashboard</h3>
          </CardHeader>
          <CardBody className="d-flex justify-content-between">
            <div>
              <div className="ml-4">
                <h2>Survey Completion Rate</h2>
                <ul className="list-unstyled my-4">
                  <li>
                    <div className="d-flex align-items-center">
                      <div>
                        <div
                          style={{ backgroundColor: "rgb(255, 0, 0)" }}
                          className="icon icon-xs icon-shape text-white shadow rounded-circle"
                        ></div>
                      </div>
                      <div>
                        <span
                          style={{ fontWeight: "bold" }}
                          className="pl-2 text-md"
                        >
                          Completed Surveys{" "}
                          <CurrencyFormat
                            value={
                              isNaN(portfolioChart[0])
                                ? 0
                                : Math.round(portfolioChart[0])
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"- "}
                          />
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <div>
                        <div
                          style={{ backgroundColor: "rgb(0, 0, 255)" }}
                          className="icon icon-xs icon-shape text-white shadow rounded-circle"
                        ></div>
                      </div>
                      <div>
                        <span
                          style={{ fontWeight: "bold" }}
                          className="pl-2 text-md"
                        >
                          Target Surveys{" "}
                          <CurrencyFormat
                            value={
                              isNaN(portfolioChart[1])
                                ? 0
                                : Math.round(portfolioChart[1])
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"- "}
                          />
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <Donut
                style={{ margin: "auto" }}
                color="black"
                indicatorColor="black"
                linecap="butt"
                animate={true}
                size={210}
                strokeWidth={20}
                styleIndicator={{
                  stroke: "Red",
                  strokeLinecap: "round",
                }}
                styleTrack={{
                  stroke: "Blue",
                  strokeWidth: 15,
                }}
                trackColor="#dfdff1"
              >
                <DonutValue
                  style={{
                    fontWeight: "bold",
                  }}
                  styleSymbol={{
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  symbol="%"
                >
                  {isNaN(
                    Math.round((portfolioChart[0] / portfolioChart[1]) * 100)
                  )
                    ? 0
                    : Math.round((portfolioChart[0] / portfolioChart[1]) * 100)}
                </DonutValue>
              </Donut>
            </div>
            <div>
              <div className="ml-4">
                <h2>Focus Outlet Surveys</h2>
                <ul className="list-unstyled my-4">
                  <li>
                    <div className="d-flex align-items-center">
                      <div>
                        <div
                          style={{ backgroundColor: "rgb(255, 165, 0)" }}
                          className="icon icon-xs icon-shape text-white shadow rounded-circle"
                        ></div>
                      </div>
                      <div>
                        <span
                          style={{ fontWeight: "bold" }}
                          className="pl-2 text-md"
                        >
                          Completed Surveys{" "}
                          <CurrencyFormat
                            value={isNaN(focusChart[0]) ? 0 : focusChart[0]}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"- "}
                          />
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="icon icon-xs icon-shape bg-yellow text-white shadow rounded-circle"></div>
                      </div>
                      <div>
                        <span
                          style={{ fontWeight: "bold" }}
                          className="pl-2 text-md"
                        >
                          Target Surveys{" "}
                          <CurrencyFormat
                            value={isNaN(focusChart[1]) ? 0 : focusChart[1]}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"- "}
                          />
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <Donut
                style={{ margin: "auto" }}
                color="black"
                indicatorColor="black"
                linecap="butt"
                animate={true}
                size={210}
                strokeWidth={20}
                styleIndicator={{
                  stroke: "Orange",
                  strokeLinecap: "round",
                }}
                styleTrack={{
                  stroke: "Yellow",
                  strokeWidth: 15,
                }}
                trackColor="#dfdff1"
              >
                <DonutValue
                  style={{
                    fontWeight: "bold",
                  }}
                  styleSymbol={{
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  symbol="%"
                >
                  {isNaN(Math.round((focusChart[0] / focusChart[1]) * 100))
                    ? 0
                    : Math.round((focusChart[0] / focusChart[1]) * 100)}
                </DonutValue>
              </Donut>
            </div>
            <div>
              <div className="ml-4">
                <h2>Outlet Feedback Analysis</h2>
                <ul className="list-unstyled my-4">
                  <li>
                    <div className="d-flex align-items-center">
                      <div>
                        <div
                          style={{ backgroundColor: "rgb(255, 0, 0)" }}
                          className="icon icon-xs icon-shape text-white shadow rounded-circle"
                        ></div>
                      </div>
                      <div>
                        <span
                          style={{ fontWeight: "bold" }}
                          className="pl-2 text-md"
                        >
                          Positive Feedback{" "}
                          <CurrencyFormat
                            value={isNaN(easChart[0]) ? 0 : easChart[0]}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"- "}
                          />
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <div>
                        <div
                          style={{ backgroundColor: "rgb(0, 0, 255)" }}
                          className="icon icon-xs icon-shape text-white shadow rounded-circle"
                        ></div>
                      </div>
                      <div>
                        <span
                          style={{ fontWeight: "bold" }}
                          className="pl-2 text-md"
                        >
                          Total Feedback{" "}
                          <CurrencyFormat
                            value={isNaN(easChart[1]) ? 0 : easChart[1]}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"- "}
                          />
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <Donut
                style={{ margin: "auto" }}
                color="black"
                indicatorColor="black"
                linecap="butt"
                animate={true}
                size={210}
                strokeWidth={20}
                styleIndicator={{
                  stroke: "Red",
                  strokeLinecap: "round",
                }}
                styleTrack={{
                  stroke: "Blue",
                  strokeWidth: 15,
                }}
                trackColor="#dfdff1"
              >
                <DonutValue
                  style={{
                    fontWeight: "bold",
                  }}
                  styleSymbol={{
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  symbol="%"
                >
                  {isNaN(Math.round((easChart[0] / easChart[1]) * 100))
                    ? 0
                    : Math.round((easChart[0] / easChart[1]) * 100)}
                </DonutValue>
              </Donut>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default OutletSurveyReport;
