import baseApi1 from "../api/BaseApi1";
import baseApi2 from "../api/BaseApi2";

export const addSurvey = async (
  outletCode,
  consumerNumber,
  currentBrand,
  name
) => {
  const payload = {
    outletCode,
    consumerNumber,
    currentBrand,
    name,
  };
  try {
    const response = await baseApi1.post(`/api/eas/web-entry`, payload, {
      headers: { "Content-type": "application/json" },
    });
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    return e.response;
  }
};

export const sendOTP = async (phone) => {
  const payload = {
    phone,
  };
  try {
    const response = await baseApi2.post(`/api/send_otp`, payload, {
      headers: { "Content-type": "application/json" },
    });
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    return e.response;
  }
};

export const verifyOTP = async (phone, typedOtp) => {
  const payload = {
    phone,
    typedOtp,
  };
  try {
    const response = await baseApi2.post(`/api/verify_otp`, payload, {
      headers: { "Content-type": "application/json" },
    });
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    return e.response;
  }
};

export const checkRetailer = async (phone) => {
  try {
    const response = await baseApi1.get(`/api/retailer/verify?phone=${phone}`);
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    return e.response;
  }
};

export const addVerifiedSurvey = async (
  region,
  area,
  territory,
  outletCode,
  retailerNumber,
  retailerName,
  consumerNumber,
  consumerName,
  isAdult
) => {
  const payload = {
    region,
    area,
    territory,
    outletCode,
    retailerNumber,
    retailerName,
    consumerNumber,
    consumerName,
    isAdult,
  };
  try {
    const response = await baseApi2.post(`/api/verified_consumer`, payload, {
      headers: { "Content-type": "application/json" },
    });
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    return e.response;
  }
};

export const getTotalVerifiedSurvey = async () => {
  try {
    const response = await baseApi2.get(`/api/total_verified_consumer`);
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    return e.response;
  }
};

export const addEntryCountSurvey = async (entryCount) => {
  try {
    const response = await baseApi2.post(
      `/api/entry_count`,
      { entryCount },
      {
        headers: { "Content-type": "application/json" },
      }
    );
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    return e.response;
  }
};

export const incrementEntryCountSurvey = async () => {
  try {
    const response = await baseApi2.patch(`/api/entry_count`);
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    return e.response;
  }
};

export const getOneEntryCountSurvey = async () => {
  try {
    const response = await baseApi2.get(`/api/entry_count`);
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    return e.response;
  }
};

export const getEntryCountSurvey = async () => {
  try {
    const response = await baseApi2.get(`/api/all_entry_count`);
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    return e.response;
  }
};
