import { Add, Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import Creatable from "react-select/creatable";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader";

const phases = [
  {
    _id: "1",
    name: "Phase - 1",
    start_date: "2025-04-01",
    end_date: "2025-04-07",
    total_questions: 11,
  },
  {
    _id: "2",
    name: "Phase - 2",
    start_date: "2025-04-08",
    end_date: "2025-04-14",
    total_questions: 11,
  },
  {
    _id: "3",
    name: "Phase - Demo",
    start_date: "2025-04-15",
    end_date: "2025-04-21",
    total_questions: 0,
  },
];

const types = [
  {
    label: "text",
    value: "text",
  },
  {
    label: "number",
    value: "number",
  },
  {
    label: "option",
    value: "option",
  },
  {
    label: "multiple-option",
    value: "multiple-option",
  },
];

const Question = ({
  index,
  question,
  types,
  questionOperations,
  followUpQuestionOperations,
}) => {
  const {
    handleRemoveQuestion,
    handleAddQuestion,
    handleAddNote,
    handleAddType,
    handleAddOptions,
    toggleRequired,
    toggleFollowUpRequired,
    handleAddFollowUpQuestionField,
  } = questionOperations;
  return (
    <div className="border mb-3 p-3">
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="mb-3">Question No - {index + 1}</h2>
        <Button
          color="danger"
          size="sm"
          outline
          onClick={() => handleRemoveQuestion(index)}
        >
          <Close />
        </Button>
      </div>
      <FormGroup className="row px-3">
        <label className="d-flex ml-4 align-items-center">
          <Input
            onChange={() => toggleRequired(index)}
            checked={question?.isRequired}
            type="checkbox"
            style={{ height: "20px", width: "20px" }}
          />
          <span className="mt-1 ml-1">Is Required</span>
        </label>
      </FormGroup>
      <FormGroup className="row">
        <Label className="form-control-label" md="2">
          Question
        </Label>
        <Col md="10">
          <Input
            onChange={(e) => handleAddQuestion(index, e.target.value)}
            type="text"
            value={question?.question}
            placeholder="Write question here"
          />
        </Col>
      </FormGroup>
      <FormGroup className="row">
        <Label className="form-control-label" md="2">
          note
        </Label>
        <Col md="10">
          <Input
            onChange={(e) => handleAddNote(index, e.target.value)}
            type="textarea"
            value={question?.note}
            placeholder="Write note here"
          />
        </Col>
      </FormGroup>
      <FormGroup className="row">
        <Label className="form-control-label" md="2">
          Response Types
        </Label>
        <Col md="10">
          <Select
            onChange={(e) => handleAddType(index, e?.value)}
            options={types || []}
            value={
              question?.type
                ? { label: question?.type, value: question?.type }
                : null
            }
            placeholder="Select the response type"
          />
        </Col>
      </FormGroup>
      {(question?.type === "option" ||
        question?.type === "multiple-option") && (
        <FormGroup className="row">
          <Label className="form-control-label" md="2">
            Options
          </Label>
          <Col md="10">
            <Creatable
              onChange={(e) =>
                handleAddOptions(
                  index,
                  e?.map((o) => o?.value)
                )
              }
              options={
                question?.options
                  ? question?.options?.map((option) => ({
                      label: option,
                      value: option,
                    }))
                  : []
              }
              value={
                question?.options
                  ? question?.options?.map((option) => ({
                      label: option,
                      value: option,
                    }))
                  : []
              }
              placeholder="Select the response options"
              isMulti
            />
          </Col>
        </FormGroup>
      )}
      {!!question?.type && question?.options?.length > 0 && (
        <FormGroup className="row px-3">
          <label className="d-flex ml-4 align-items-center">
            <Input
              onChange={() => toggleFollowUpRequired(index)}
              checked={question?.isFollowUpRequired}
              type="checkbox"
              style={{ height: "20px", width: "20px" }}
            />
            <span className="mt-1 ml-1">Is Follow Up Required</span>
          </label>
        </FormGroup>
      )}
      {question?.isFollowUpRequired &&
        question?.questions &&
        question?.options?.length > 0 && (
          <div className="mx-4">
            <h3 className="mb-2 bold">Follow Up Questions :</h3>
            <div>
              {question.questions.map((subquestion, subindex) => (
                <FollowUpQuestion
                  key={subindex}
                  index={index}
                  subindex={subindex}
                  question={subquestion}
                  types={types}
                  options={question?.options || []}
                  followUpQuestionOperations={followUpQuestionOperations}
                />
              ))}
            </div>
            <div
              onClick={() => handleAddFollowUpQuestionField(index)}
              style={{ cursor: "pointer" }}
              className="text-primary d-inline-block"
            >
              <Add /> Add New Follow Question.
            </div>
          </div>
        )}
    </div>
  );
};

const FollowUpQuestion = ({
  index,
  subindex,
  question,
  types,
  options,
  followUpQuestionOperations,
}) => {
  const {
    handleRemoveFollowUpQuestion,
    handleAddFollowUpReference,
    handleAddFollowUpQuestion,
    handleAddFollowUpNote,
    handleAddFollowUpType,
    handleAddFollowUpOptions,
    toggleRequired,
  } = followUpQuestionOperations;

  return (
    <div className="border mb-3 p-3">
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="mb-3">
          Question No - {index + 1}.{subindex + 1}
        </h2>
        <Button
          color="danger"
          size="sm"
          outline
          onClick={() => handleRemoveFollowUpQuestion(index, subindex)}
        >
          <Close />
        </Button>
      </div>

      <FormGroup className="row px-3">
        <label className="d-flex ml-4 align-items-center">
          <Input
            onChange={() => toggleRequired(index, subindex)}
            checked={question?.isRequired}
            type="checkbox"
            style={{ height: "20px", width: "20px" }}
          />
          <span className="mt-1 ml-1">Is Required</span>
        </label>
      </FormGroup>
      <FormGroup className="row">
        <Label className="form-control-label" md="2">
          Reference
        </Label>
        <Col md="10">
          <Select
            onChange={(e) =>
              handleAddFollowUpReference(index, subindex, e?.value)
            }
            options={
              options
                ? options?.map((option) => ({ label: option, value: option }))
                : []
            }
            value={
              question?.reference
                ? { label: question?.reference, value: question?.reference }
                : null
            }
            placeholder="Select the response type"
          />
        </Col>
      </FormGroup>
      <FormGroup className="row">
        <Label className="form-control-label" md="2">
          Question
        </Label>
        <Col md="10">
          <Input
            onChange={(e) =>
              handleAddFollowUpQuestion(index, subindex, e.target.value)
            }
            type="text"
            value={question?.question}
            placeholder="Write question here"
          />
        </Col>
      </FormGroup>
      <FormGroup className="row">
        <Label className="form-control-label" md="2">
          note
        </Label>
        <Col md="10">
          <Input
            onChange={(e) =>
              handleAddFollowUpNote(index, subindex, e.target.value)
            }
            type="textarea"
            value={question?.note}
            placeholder="Write note here"
          />
        </Col>
      </FormGroup>
      <FormGroup className="row">
        <Label className="form-control-label" md="2">
          Response Types
        </Label>
        <Col md="10">
          <Select
            onChange={(e) => handleAddFollowUpType(index, subindex, e?.value)}
            options={types || []}
            value={
              question?.type
                ? { label: question?.type, value: question?.type }
                : null
            }
            placeholder="Select the response type"
          />
        </Col>
      </FormGroup>
      {(question?.type === "option" ||
        question?.type === "multiple-option") && (
        <FormGroup className="row">
          <Label className="form-control-label" md="2">
            Options
          </Label>
          <Col md="10">
            <Creatable
              onChange={(e) => handleAddFollowUpOptions(index, subindex, e)}
              options={[]}
              placeholder="Select the response options"
              isMulti
            />
          </Col>
        </FormGroup>
      )}
    </div>
  );
};

const AddOutletSurvey = () => {
  const { id } = useParams();

  const [phase, setPhase] = useState({});
  const [questions, setQuestions] = React.useState([
    {
      isRequired: true,
      isFollowUpRequired: false,
      question: "",
      note: "",
      type: "",
      options: [],
      value: "",
    },
  ]);

  useEffect(() => {
    const phase = phases?.find((phase) => phase?._id === id) || {};
    setPhase(phase);
  }, [id]);

  const handleAddQuestionField = () => {
    setQuestions([
      ...questions,
      {
        isRequired: true,
        isFollowUpRequired: false,
        question: "",
        note: "",
        type: "",
        options: [],
        value: "",
      },
    ]);
  };

  const handleAddFollowUpQuestionField = (index) => {
    const newQuestions = [...questions];
    newQuestions[index]?.questions.push({
      isRequired: true,
      reference: "",
      question: "",
      note: "",
      type: "",
      options: [],
      value: "",
    });
    setQuestions(newQuestions);
  };

  const handleRemoveQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  const handleRemoveFollowUpQuestion = (index, subindex) => {
    const newQuestions = [...questions];
    newQuestions[index].questions.splice(subindex, 1);
    setQuestions(newQuestions);
  };

  const handleAddQuestion = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].question = value;
    setQuestions(newQuestions);
  };

  const handleAddFollowUpReference = (index, subindex, value) => {
    const newQuestions = [...questions];
    newQuestions[index].questions[subindex].reference = value;
    setQuestions(newQuestions);
  };

  const handleAddFollowUpQuestion = (index, subindex, value) => {
    const newQuestions = [...questions];
    newQuestions[index].questions[subindex].question = value;
    setQuestions(newQuestions);
  };

  const handleAddNote = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].note = value;
    setQuestions(newQuestions);
  };

  const handleAddFollowUpNote = (index, subindex, value) => {
    const newQuestions = [...questions];
    newQuestions[index].questions[subindex].note = value;
    setQuestions(newQuestions);
  };

  const handleAddType = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].type = value;
    setQuestions(newQuestions);
  };

  const handleAddFollowUpType = (index, subindex, value) => {
    const newQuestions = [...questions];
    newQuestions[index].questions[subindex].type = value;
    setQuestions(newQuestions);
  };

  const handleAddOptions = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].options = value;
    setQuestions(newQuestions);
  };

  const handleAddFollowUpOptions = (index, subindex, value) => {
    const newQuestions = [...questions];
    newQuestions[index].questions[subindex].options = value;
    setQuestions(newQuestions);
  };

  const toggleRequired = (index, subindex) => {
    if (typeof subindex === "number") {
      const newQuestions = [...questions];
      newQuestions[index].questions[subindex].isRequired =
        !newQuestions[index].questions[subindex].isRequired;
      setQuestions(newQuestions);
    } else {
      const newQuestions = [...questions];
      newQuestions[index].isRequired = !newQuestions[index].isRequired;
      setQuestions(newQuestions);
    }
  };

  const toggleFollowUpRequired = (index) => {
    const newQuestions = [...questions];
    newQuestions[index].isFollowUpRequired =
      !newQuestions[index].isFollowUpRequired;

    if (newQuestions[index].isFollowUpRequired) {
      if (!newQuestions[index].questions) {
        newQuestions[index].questions = [];
      }
      if (newQuestions[index].questions.length === 0) {
        newQuestions[index].questions.push({
          isRequired: true,
          reference: "",
          question: "",
          note: "",
          type: "",
          options: [],
          value: "",
        });
      }
    }
    setQuestions(newQuestions);
  };

  const questionOperations = {
    handleRemoveQuestion,
    handleAddQuestion,
    handleAddNote,
    handleAddType,
    handleAddOptions,
    toggleRequired,
    toggleFollowUpRequired,
    handleAddFollowUpQuestionField,
  };

  const followUpQuestionOperations = {
    handleRemoveFollowUpQuestion,
    handleAddFollowUpReference,
    handleAddFollowUpQuestion,
    handleAddFollowUpNote,
    handleAddFollowUpType,
    handleAddFollowUpOptions,
    toggleRequired,
  };

  return (
    <div>
      <SimpleHeader name="Add outlet survey" parentName="Survey" />
      <Container className="mt--6" fluid>
        <Card>
          <CardBody>
            <h1>Add {phase?.name} Survey Questions</h1>
            <p className="d-flex gap-2">
              <span>
                {new Date(phase?.start_date).toLocaleDateString("en-US", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
              <span className="mx-2">-</span>
              <span>
                {new Date(phase?.end_date).toLocaleDateString("en-US", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
            </p>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div>
              <div>
                {questions.map((question, index) => (
                  <Question
                    className="border mb-3 p-3"
                    key={index}
                    index={index}
                    question={question}
                    types={types}
                    questionOperations={questionOperations}
                    followUpQuestionOperations={followUpQuestionOperations}
                  />
                ))}
              </div>
              <div className="d-flex justify-content-between">
                <Button
                  onClick={() => handleAddQuestionField()}
                  color="primary"
                  outline
                >
                  Add New Question
                </Button>
                <Button color="success">Submit</Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default AddOutletSurvey;
