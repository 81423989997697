import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader";

const questions_data = {
  1: [
    {
      _id: "q1_1",
      isRequired: true,
      isFollowUpRequired: false,
      question: "বিক্রেতার নাম:",
      note: "",
      type: "text",
      options: [],
    },
    {
      _id: "q1_2",
      isRequired: true,
      isFollowUpRequired: false,
      question: "লিঙ্গ:",
      note: "",
      type: "option",
      options: ["পুরুষ", "মহিলা", "অন্যান্য"],
    },
    {
      _id: "q1_3",
      isRequired: true,
      isFollowUpRequired: false,
      question: "জন্মতারিখ:",
      note: "",
      type: "text",
      options: [],
    },
    {
      _id: "q1_4",
      isRequired: true,
      isFollowUpRequired: false,
      question: "ধর্ম:",
      note: "",
      type: "text",
      options: [],
    },
    {
      _id: "q1_5",
      isRequired: false,
      isFollowUpRequired: false,
      question: "শারীরিক প্রতিবন্ধকতা (যদি থাকে):",
      note: "",
      type: "text",
      options: [],
    },
    {
      _id: "q1_6",
      isRequired: true,
      isFollowUpRequired: true,
      question: "আপনি কি একাই দোকান পরিচালনা করেন?",
      note: "শুধুমাত্র 'না' হলে পরবর্তী প্রশ্ন প্রযোজ্য",
      type: "option",
      options: ["হ্যাঁ", "না"],

      questions: [
        {
          _id: "q1_6_1",
          isRequired: true,
          reference: "না",
          question: "আপনার সহায়ক ব্যক্তি কে?",
          note: "",
          type: "text",
          options: [],
        },
        {
          _id: "q1_6_2",
          isRequired: true,
          reference: "না",
          question: "আপনি দৈনিক কত সময় দোকান পরিচালনা করেন?",
          note: "",
          type: "text",
          options: [],
        },
      ],
    },
    {
      _id: "q1_7",
      isRequired: true,
      isFollowUpRequired: false,
      question: "আপনার ব্যবসার ধরন:",
      note: "",
      type: "text",
      options: [],
    },
    {
      _id: "q1_8",
      isRequired: true,
      isFollowUpRequired: true,
      question: "এই দোকান কি আপনার একক মালিকানাধীন নাকি অংশীদারিত্বে রয়েছে?",
      note: "যদি অংশীদার থাকে, অংশীদারের সংখ্যা এবং তাদের সাথে পারিবারিক সম্পর্ক উল্লেখ করুন",
      type: "option",
      options: ["একক মালিকানা", "অংশীদারিত্ব"],

      questions: [
        {
          _id: "q1_8_1",
          isRequired: true,
          reference: "অংশীদারিত্ব",
          question: "অংশীদারের সংখ্যা এবং পারিবারিক সম্পর্ক:",
          note: "",
          type: "text",
          options: [],
        },
      ],
    },
    {
      _id: "q1_9",
      isRequired: true,
      isFollowUpRequired: true,
      question: "এর আগে আপনি কি অন্য কোনো ব্যবসা বা পেশায় নিয়োজিত ছিলেন?",
      note: "যদি হ্যাঁ, তাহলে বিস্তারিত লিখুন",
      type: "option",
      options: ["হ্যাঁ", "না"],
      questions: [
        {
          _id: "q1_9_1",
          isRequired: true,
          reference: "হ্যাঁ",
          question: "এর আগে আপনি কী করতেন?",
          note: "",
          type: "text",
          options: [],
        },
      ],
    },
    {
      _id: "q1_10",
      isRequired: true,
      isFollowUpRequired: true,
      question: "এই ব্যবসা বিক্রয়ের ক্ষেত্রে আপনার ভবিষ্যৎ পরিকল্পনা কী?",
      note: "যদি হ্যাঁ, তাহলে পরিকল্পনাটি উল্লেখ করুন",
      type: "option",
      options: ["হ্যাঁ", "না"],
      questions: [
        {
          _id: "q1_10_1",
          isRequired: true,
          reference: "হ্যাঁ",
          question: "আপনার পরিকল্পনাটি কী?",
          note: "",
          type: "text",
          options: [],
        },
        {
          _id: "q1_10_2",
          isRequired: true,
          reference: "না",
          question: "এই ব্যবসা নিয়ে আপনার ভবিষ্যৎ পরিকল্পনা কী?",
          note: "",
          type: "text",
          options: [],
        },
      ],
    },
  ],
  2: [
    {
      _id: "q2_1",
      isRequired: true,
      isFollowUpRequired: true,
      question: "আপনি কি পরিবারের একমাত্র উপার্জনক্ষম সদস্য?",
      note: "শুধুমাত্র 'না' হলে পরবর্তী প্রশ্ন প্রযোজ্য",
      type: "option",
      options: ["হ্যাঁ", "না"],
      questions: [
        {
          _id: "q2_1_1",
          isRequired: true,
          reference: "না",
          question: "পরিবারের অন্যান্য উপার্জনকারী সদস্য কতজন?",
          note: "",
          type: "number",
          options: [],
        },
      ],
    },
    {
      _id: "q2_2",
      isRequired: true,
      isFollowUpRequired: false,
      question: "বিবাহিত / অবিবাহিত:",
      note: "",
      type: "option",
      options: ["বিবাহিত", "অবিবাহিত"],
    },
    {
      _id: "q2_3",
      isRequired: false,
      isFollowUpRequired: true,
      question: "আপনা সন্তান আছে?",
      note: "শুধুমাত্র 'হ্যাঁ' হলে পরবর্তী প্রশ্ন প্রযোজ্য",
      type: "option",
      options: ["হ্যাঁ", "না"],
      questions: [
        {
          _id: "q2_3_1",
          isRequired: false,
          reference: "হ্যাঁ",
          question: "ছেলে সংখ্যা",
          note: "",
          type: "number",
          options: [],
        },
        {
          _id: "q2_3_2",
          isRequired: false,
          reference: "হ্যাঁ",
          question: "মেয়ে সংখ্যা",
          note: "",
          type: "number",
          options: [],
        },
      ],
    },
  ],
};

const Question = ({ question, index, subindex }) => {
  return (
    <div key={index} className="mb-4">
      <div className="mb-2">
        <p className="mb-0" style={{ fontWeight: "bold", lineHeight: "1" }}>
          {index + 1}. {subindex !== undefined && subindex + 1 + "."}{" "}
          {question?.question}
        </p>
        {question?.note && (
          <small style={{ lineHeight: "1" }}>({question.note})</small>
        )}
      </div>
      <div className="">
        {question?.type === "text" && (
          <div>
            <Input
              type="text"
              name={`question-${index}`}
              placeholder="Enter your answer"
            />
          </div>
        )}
        {question?.type === "number" && (
          <div>
            <Input
              type="number"
              name={`question-${index}`}
              placeholder="Enter your answer"
            />
          </div>
        )}
        {question?.type === "option" && (
          <div className="row ml-2">
            {question?.options.map((option, optIndex) => (
              <div className="col-6" key={optIndex}>
                <Input
                  type="radio"
                  name={`question-${index}`}
                  id={`option-${index}-${optIndex}`}
                />
                <Label for={`option-${index}-${optIndex}`}>{option}</Label>
              </div>
            ))}
          </div>
        )}
        {question?.type === "multiple-option" && (
          <div className="row ml-2">
            {question.options.map((option, optIndex) => (
              <div className="col-6" key={optIndex}>
                <Input
                  type="checkbox"
                  name={`question-${index}`}
                  id={`option-${index}-${optIndex}`}
                />
                <Label for={`option-${index}-${optIndex}`}>{option}</Label>
              </div>
            ))}
          </div>
        )}
      </div>
      {question?.isFollowUpRequired && question?.questions?.length > 0 && (
        <div className="p-2 border mt-1">
          {question?.questions?.map((question, subindex) => (
            <Question index={index} subindex={subindex} question={question} />
          ))}
        </div>
      )}
    </div>
  );
};

const OutletSurvey = () => {
  const currentMonth =
    new Date().getFullYear() +
    "-" +
    (new Date().getMonth() + 1).toString().padStart(2, "0");
  const [selectedMonth, setSelectedMonth] = React.useState(currentMonth);
  const [weeks, setWeeks] = React.useState([]);
  const [questionsModalOpen, setQuestionsModalOpen] = React.useState(false);
  const [phasesModalOpen, setPhasesModalOpen] = React.useState(false);
  const [selectedPhase, setSelectedPhase] = React.useState(null);
  const [phases, setPhases] = React.useState([
    {
      _id: "1",
      name: "Phase - 1",
      start_date: "2025-04-01",
      end_date: "2025-04-07",
      total_questions: 11,
      isCompleted: true,
    },
    {
      _id: "2",
      name: "Phase - 2",
      start_date: "2025-04-08",
      end_date: "2025-04-14",
      total_questions: 3,
      isCompleted: true,
    },
    {
      _id: "3",
      name: "Phase - Demo",
      start_date: "2025-04-15",
      end_date: "2025-04-21",
      total_questions: 0,
      isCompleted: false,
    },
  ]);
  const [newPhase, setNewPhase] = React.useState({
    name: "",
    start_date: "",
    end_date: "",
    total_questions: 0,
    isCompleted: false,
  });
  const [filteredPhases, setFilteredPhases] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);

  const handleNewPhaseChange = (e) => {
    const { name, value } = e.target;
    setNewPhase((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddNewPhase = () => {
    const newPhases = [...phases, newPhase];

    setNewPhase({
      name: "",
      start_date: "",
      end_date: "",
      total_questions: 0,
      isCompleted: false,
    });
    setPhases(newPhases);
    setPhasesModalOpen(false);
  };

  const history = useHistory();

  const navigateToAdd = (id) => {
    history.push(`/admin/add-outlet-survey/${id}`);
  };

  const navigateToUpdate = (id) => {
    history.push(`/admin/update-outlet-survey/${id}`);
  };

  // Function to calculate weeks for the selected month
  const calculateWeeks = (selectedMonth) => {
    const [year, month] = selectedMonth.split("-");
    const firstDayOfMonth = new Date(year, month - 1, 1);
    const lastDayOfMonth = new Date(year, month, 0);
    const weeksArray = [];

    let currentWeek = [];
    let currentDate = new Date(firstDayOfMonth);

    while (currentDate <= lastDayOfMonth) {
      currentWeek.push(new Date(currentDate));
      if (
        currentWeek.length === 7 ||
        currentDate.getDate() === lastDayOfMonth.getDate()
      ) {
        weeksArray.push(currentWeek);
        currentWeek = [];
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return weeksArray;
  };

  React.useEffect(() => {
    const weeksArray = calculateWeeks(selectedMonth);
    setWeeks(weeksArray);
  }, [selectedMonth]);

  const openModal = (id) => {
    setSelectedPhase(id);
    setQuestionsModalOpen(true);
  };

  const closeModal = () => {
    setQuestionsModalOpen(false);
    setSelectedPhase(null);
  };

  useEffect(() => {
    const getFilteredPhases = () => {
      const [year, month] = selectedMonth.split("-");
      const startOfMonth = new Date(year, month - 1, 1);
      const endOfMonth = new Date(year, month, 0);

      return phases.filter((phase) => {
        const phaseStart = new Date(phase.start_date);
        const phaseEnd = new Date(phase.end_date);

        return (
          (phaseStart >= startOfMonth && phaseStart <= endOfMonth) ||
          (phaseEnd >= startOfMonth && phaseEnd <= endOfMonth) ||
          (phaseStart <= startOfMonth && phaseEnd >= endOfMonth)
        );
      });
    };
    setFilteredPhases(getFilteredPhases() || []);
  }, [selectedMonth, phases]);

  return (
    <div>
      <SimpleHeader name="Outlet Survey" parentName="Survey" />
      <Container className="mt--6" fluid>
        <Card>
          <CardBody>
            <div>
              <FormGroup className="row">
                <Label
                  className="form-control-label"
                  htmlFor="example-month-input"
                  md="2"
                >
                  Month
                </Label>
                <Col md="10">
                  <Input
                    defaultValue={currentMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    id="example-month-input"
                    type="month"
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="d-flex justify-content-end">
              <Button color="primary" onClick={() => setPhasesModalOpen(true)}>
                Add New Phase
              </Button>
            </div>
          </CardBody>
        </Card>
        {filteredPhases?.length > 0 ? (
          <Card>
            <CardBody>
              <div>
                <Row>
                  {filteredPhases?.map((item, index) => (
                    <Col key={index} md="6">
                      <div
                        className="border mb-3 p-3"
                        style={{ cursor: "pointer" }}
                      >
                        <h5>{item?.name}</h5>
                        <p className="d-flex gap-2">
                          <span>
                            {new Date(item?.start_date).toLocaleDateString(
                              "en-US",
                              {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </span>
                          <span className="mx-2">-</span>
                          <span>
                            {new Date(item?.end_date).toLocaleDateString(
                              "en-US",
                              {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </span>
                        </p>
                        <p>
                          Total Questions:{" "}
                          <snap className="bold">{item.total_questions}</snap>
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            {item?.isCompleted ? (
                              <Button
                                onClick={() => navigateToUpdate(item?._id)}
                                color="primary"
                                outline
                              >
                                Update Questions
                              </Button>
                            ) : (
                              <Button
                                onClick={() => navigateToAdd(item?._id)}
                                color="primary"
                                outline
                              >
                                Add Questions
                              </Button>
                            )}
                          </div>
                          <div>
                            {item?.isCompleted && (
                              <Button
                                onClick={() => {
                                  setQuestions(
                                    questions_data?.[item?._id] || []
                                  );
                                  openModal(item?._id);
                                }}
                              >
                                View
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardBody>
              <div className="d-flex justify-content-center">
                <h5>No Phase Found</h5>
              </div>
            </CardBody>
          </Card>
        )}
      </Container>

      {/* Modal to add new phase */}
      <Modal isOpen={phasesModalOpen} toggle={() => setPhasesModalOpen(false)}>
        <ModalHeader toggle={() => setPhasesModalOpen(false)}>
          Add New Phase
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="phaseName">Phase Name</Label>
            <Input
              type="text"
              name="name"
              id="phaseName"
              placeholder="Enter phase name (e.g., Phase - 1)"
              value={newPhase.name}
              onChange={handleNewPhaseChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="startDate">Start Date</Label>
            <Input
              type="date"
              name="start_date"
              id="startDate"
              value={newPhase.start_date}
              onChange={handleNewPhaseChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="endDate">End Date</Label>
            <Input
              type="date"
              name="end_date"
              id="endDate"
              value={newPhase.end_date}
              onChange={handleNewPhaseChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setPhasesModalOpen(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleAddNewPhase}>
            Add Phase
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal to display questions */}
      <Modal isOpen={questionsModalOpen} toggle={closeModal} size="lg">
        <ModalHeader toggle={closeModal}>
          Questions for{" "}
          {selectedPhase !== null
            ? phases?.find((phase) => phase?._id === selectedPhase)?.name
            : ""}
        </ModalHeader>
        <ModalBody>
          {questions?.map((question, index) => (
            <Question index={index} question={question} />
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default OutletSurvey;
